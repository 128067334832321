/**
 * Makes a call to Galaxie API to send a machine's file format.
 *
 * @param {string} sessionToken
 * @param {string} code
 */
export default async function userSelectKaraokeFileFormatOrModel({ galaxieApiUrl, sessionToken, code }) {
  try {
    // fetch to galaxie api
    const response = await fetch(`${galaxieApiUrl}/api/UserSelectKaraokeFileFormatOrModel`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        session_token: sessionToken,
        code
      }),
    });

    if (!response.ok) {
      throw new Error();
    }

    const json = await response.json();

    if (json.error) {
      throw json.error;
    }

    return json;
  }
  catch (error) {
    console.error("An error occured while sending file format: ", error);
    return null;
  }
}
