import { call, put, select, take } from "redux-saga/effects";

import scrollToTop from "../service/effect/scrollToTop";
import hasProductRegistrationRequiredFlag from "../service/network/hasProductRegistrationRequiredFlag";
import updateRegistrationRequestedFlag from "../service/network/updateRegistrationRequestedFlag";
import submitSerialNumber from "../service/network/submitSerialNumber";

import { redirectToSkip } from "../sagas/appSaga";
import { PAGE_ACTION_TRIGGERED, SERIAL_NUMBER_SUBMITTED, REGISTRATION_SKIPPED } from "../actions/actionTypes";
import { nextPage, pageActionTriggered } from "../actions/navigationActions";
import { machineFound } from "../actions/machineActions";
import { appStatusUpdated } from "../actions/contextActions";
import { PAGES, MACHINE_STATUS } from "../constants";

export default function* machineSerialSaga() {
  const rootAction = yield take(action => action.type === PAGE_ACTION_TRIGGERED && action.page === PAGES.MACHINE);
  const action = rootAction.child;
  const state = yield select();

  switch (action.type) {
    case SERIAL_NUMBER_SUBMITTED:
      try {
        yield put(pageActionTriggered(PAGES.MACHINE, appStatusUpdated(MACHINE_STATUS.SENDING)));

        const machine = yield call(submitSerialNumber, {
          karaokeRegistrationUrl: state.config.karaokeRegistrationUrl,
          serialNumber: state.page.Machine.serialNumber
        });

        if (machine !== null) {
          yield put(pageActionTriggered(PAGES.MACHINE, machineFound(machine)));

          if (action.skipMachinePreview && !machine.alreadyRegistered) {
            yield put(pageActionTriggered(PAGES.MACHINE, nextPage()));
          } else {
            yield put(pageActionTriggered(PAGES.MACHINE, appStatusUpdated(MACHINE_STATUS.SHOW)));
          }
        }
        else {
          yield put(pageActionTriggered(PAGES.MACHINE, appStatusUpdated(MACHINE_STATUS.NOT_FOUND)));
        }
      }
      catch (error) {
        yield put(pageActionTriggered(PAGES.MACHINE, appStatusUpdated(MACHINE_STATUS.NETWORK_ERROR)));
      }
      finally {
        yield call(scrollToTop);  // Scroll up to show error messages
      }
      break;

    case REGISTRATION_SKIPPED:
      try {
        yield put(pageActionTriggered(PAGES.MACHINE, appStatusUpdated(MACHINE_STATUS.SENDING)));

        const hasProductRegistrationRequired = yield call(hasProductRegistrationRequiredFlag, {
          email: state.email,
          karaokeRegistrationUrl: state.config.karaokeRegistrationUrl
        });

        if (hasProductRegistrationRequired) {
          yield call(updateRegistrationRequestedFlag, {
            email: state.email,
            karaokeRegistrationUrl: state.config.karaokeRegistrationUrl
          });
        }

        yield put(pageActionTriggered(PAGES.MACHINE, appStatusUpdated(MACHINE_STATUS.AWAIT_INPUT)));
        yield call(redirectToSkip);
      }
      catch (error) {
        yield put(pageActionTriggered(PAGES.MACHINE, appStatusUpdated(MACHINE_STATUS.NETWORK_ERROR)));
        yield call(scrollToTop);  // Scroll up to show error message
      }
      break;

    default:
      break;
  }
}
