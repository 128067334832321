import { runSaga, stdChannel } from 'redux-saga';


/**
 * Inspired by Elm's eponymous function.
 * Best explained by a series of examples:
 *
 *    { "yes": true, "no": false }      =>  "yes"
 *    { "yes": true, "also": true }     =>  "also yes"
 *    { "nope": false, "nada": false }  =>  ""
 *
 * For convenience, these classes will also be filtered out:
 *
 *   - ""
 *   - "null"
 *   - "undefined"
 *
 * @param {{ [key:string]: boolean }} classes
 * @return {string}
 */
export function classList(classes) {
  return Object.keys(classes)
    .filter(className => !["", "null", "undefined"].includes(className))
    .filter(className => classes[className])
    .join(" ");
}


/**
 * Returns a promise which resolves after a given duration (in milliseconds).
 *
 * @param {number} durationMillis
 * @return {Promise<void>}
 */
export function delay(durationMillis) {
  return new Promise((resolve) => setTimeout(() => resolve(), durationMillis));
}


/**
 * Runs a Redux saga from beginning to end and accumulates the actions dispatched in an array.
 *
 * @param {Saga} saga
 * @param {Action} initialAction
 * @param {State} state
 * @return {Array}
 */
// inspired from https://dev.to/phil/the-best-way-to-test-redux-sagas-4hib
export async function recordSaga(saga, initialAction, state) {
  const dispatched = [];

  const channel = stdChannel();
  const runningSaga = runSaga(
    {
      channel,
      dispatch: action => dispatched.push(action),
      getState: () => state
    },
    saga
  );
  channel.put(initialAction);

  await runningSaga.done;

  return dispatched;
}


/**
 * Returns the local utc offset in standar iso format
 * @param {date} date
 * @return {string}
 */
export function isoUtcOffsetString(date) {
  var offset = date.getTimezoneOffset(),
	offsetHrs = parseInt(Math.abs(offset/60)),
  offsetMin = Math.abs(offset%60);

  if(offset === 0) {
    return "Z"
  }

  //the utc offset is positive when you are behind the utc, so with the sign
  // ('0' + offsetHrs).slice(-2) to add a zero if the value is less than 10.
  return `${offset < 0 ? "+" : "-"}${("0" + offsetHrs).slice(-2)}:${("0" + offsetMin).slice(-2)}`
}
