import React from "react";
import { connect } from "react-redux";
import { compose } from "redux";

import Button from "../../component/Button/Button";
import LabelledCheckbox from "../../component/LabelledCheckbox/LabelledCheckbox";
import lokaliseStr from "../../service/util/lokaliseStr";
import { classList } from "../../service/util/util";

import { pageActionTriggered } from "../../actions/navigationActions";
import { continueClicked } from "../../actions/navigationActions";
import { communicationPreferenceChanged, newsletterPreferenceChanged } from "../../actions/communicationsActions"

import "./Communications.css";
import { PAGES, COMMUNICATIONS_STATUS } from "../../constants";


export function Communications({ active, dispatch, langcode, state }) {
  if (!active) {
    return null;
  }

  const moreInfoHref = lokaliseStr(langcode, "Communications.MoreInfoHref");

  return (
    <div id="page-communications" className={classList({ "sending": state.status === COMMUNICATIONS_STATUS.SENDING })}>
      {(state.status === COMMUNICATIONS_STATUS.MUST_ACCEPT_COMMUNICATIONS_ERROR) && (
        <div className="error-message">{lokaliseStr(langcode, "Communications.MustAcceptCommunicationsError")}</div>
      )}
      {(state.status === COMMUNICATIONS_STATUS.NETWORK_ERROR) && (
        <div className="error-message">{lokaliseStr(langcode, "Common.NetworkError")}</div>
      )}

      <LabelledCheckbox
        className="checkbox"
        checked={state.acceptedSingingMachineCommunication}
        onChange={compose(dispatch, communicationPreferenceChanged)}>

        {lokaliseStr(langcode, "Communications.LegalText")}
      </LabelledCheckbox>

      <LabelledCheckbox
        className="checkbox"
        checked={state.acceptedSingingMachineNewsletter}
        onChange={compose(dispatch, newsletterPreferenceChanged)}>

        {lokaliseStr(langcode, "Communications.NewsletterText")}
      </LabelledCheckbox>
      
      <div className="align-labelled-checkbox-text">
        <p>{lokaliseStr(langcode, "Communications.ReceiptMessage")}</p>
      </div>
      
      <div className="centered-link">
        <p>{lokaliseStr(langcode, "Communications.MoreInfoText")}</p>
        <p><a href={"https://" + moreInfoHref} rel="noopener noreferrer" target="_blank">{moreInfoHref}</a>.</p>
      </div>

      <Button centered className="continue-button" onClick={() => dispatch(continueClicked())}>
        {lokaliseStr(langcode, "Common.Continue")}
      </Button>
    </div>
  );
}



// REDUXIFY IT!


const mapStateToProps = (state) => ({
  active: state.pageId === PAGES.COMMUNICATIONS,
  langcode: state.langcode,
  state: state.page.Communications,
});

const mapDispatchToProps = (dispatch) => ({
  dispatch: (child) => dispatch(pageActionTriggered(PAGES.COMMUNICATIONS, child)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Communications);
