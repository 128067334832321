export default function appInitialize() {
  return {
    // Shared state
    email: "",
    config: {},
    country: "",
    gxSession: "",
    history: {},
    langcode: "en",
    platform: "WEB",
    skipPath: "",
    songCredits: 0,
    successfullyRegistredMachine: false,
    successPath: "",
    timeArrivedToPage: 0,
    serial: "",

    // Page state
    pageId: "",
    page: {
      Communications: null,
      Machine: null,
      ThankYou: null,
    },
  };
}
