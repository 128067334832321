// CONTEXT
export const APP_INITIALIZED = "context/AppInitialized";
export const APP_STATUS_UPDATED = "context/AppStatusUpdated";

// NAVIGATION
export const PAGE_TRANSITION_TRIGGERED = "navigation/PageTransitionTriggered";
export const PAGE_ACTION_TRIGGERED = "navigation/PageActionTriggered";
export const HISTORY_UPDATED = "navigation/HistoryUpdated";
export const PAGE_VIEW_CHANGED = "navigation/PageViewChanged";
export const PAGE_STATE_UPDATED = "navigation/PageStateUpdated";
export const PAGE_TIME_ARRIVED_UPDATED = "navigation/PageTimeArrivedUpdated"; 
export const NEXT_PAGE = "navigation/NextPage";
export const CONTINUE_SELECTED = "navigation/ContinueSelected";
export const REGISTRATION_SKIPPED = "navigation/RegistrationSkipped";

// COMMUNICATIONS
export const COMMUNICATION_PREFERENCE_CHANGED = "communications/CommunicationPreferencesChanged";
export const NEWSLETTER_PREFERENCE_CHANGED = "communications/NewsletterPreferencesChanged";

// MACHINE
export const MACHINE_FOUND = "machine/MachineFound";
export const MACHINE_SUCCESSFULLY_REGISTERED = "machine/MachineSuccessfullyRegistered";
export const SERIAL_NUMBER_CHANGED = "machine/SerialNumberChanged";
export const SERIAL_NUMBER_SUBMITTED = "machine/SerialNumberSubmitted";