/**
 * Makes a call to the Karaoke Registration microservice to check if the registration is required.
 *
 * @param {string} email
 * @param {string} karaokeRegistrationUrl
 * @return {boolean}
 */
export default async function hasProductRegistrationRequiredFlag({ email, karaokeRegistrationUrl }) {
  try {
    const apiPath = `/karaoke-registration/V2/registration/${email}/status`;
    const result = await fetch(karaokeRegistrationUrl + apiPath, {"mode": "cors"});

    if (!result.ok) {
      throw result.statusText;
    }

    const body = await result.json();
    const isRegistrationRequested = body.is_registration_requested;
    if (body.is_registration_requested === undefined) {
      throw new Error("is_registration_requested is missing");
    }

    return isRegistrationRequested;
  }
  catch (error) {
    console.error("An error occured while fetching isRegistrationRequested: ", error);
    return false;
  }
}
