import {
  MACHINE_FOUND,
  MACHINE_SUCCESSFULLY_REGISTERED,
  SERIAL_NUMBER_CHANGED,
  NEXT_PAGE,
  APP_STATUS_UPDATED,
} from "../actions/actionTypes";
import { MACHINE_STATUS } from "../constants";

export default function reducer(state, action) {
  switch (action.type) {

    case NEXT_PAGE:
      return { ...state, status: MACHINE_STATUS.AWAIT_INPUT };

    case MACHINE_FOUND:
      return {
        ...state,
        alreadyRegistered: action.machine.alreadyRegistered,
        machineImageSrc: action.machine.imageSrc,
        machineMediaFormat: action.machine.mediaFormat,
        machineName: action.machine.name,
      };

    case MACHINE_SUCCESSFULLY_REGISTERED:
      return { ...state, songCredits: action.songCredits, successfullyRegisteredMachine: true };

    case SERIAL_NUMBER_CHANGED:
      return { ...state, serialNumber: action.value, status: MACHINE_STATUS.AWAIT_INPUT };

    case APP_STATUS_UPDATED:
      return { ...state, status: action.status };

    default:
      return state;
  }
}
