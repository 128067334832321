import {
  COMMUNICATION_PREFERENCE_CHANGED,
  NEWSLETTER_PREFERENCE_CHANGED,
} from "./actionTypes";

export function communicationPreferenceChanged(value) {
  return { type: COMMUNICATION_PREFERENCE_CHANGED, value };
}

export function newsletterPreferenceChanged(value) {
  return { type: NEWSLETTER_PREFERENCE_CHANGED, value };
}
