import React from "react";
import { withRouter } from "react-router-dom";

import Communications from "../../page/Communications/Communications";
import Machine from "../../page/Machine/Machine";
import ThankYou from "../../page/ThankYou/ThankYou";

import { historyUpdated } from "../../actions/navigationActions";

import "./App.css";


function App({ dispatch, history }) {
  React.useEffect(() => {
    dispatch(historyUpdated(history));
    // eslint-disable-next-line
  }, []);

  return (
    <div id="app" className="theme-dark">
      <div className="content">
        <Communications />
        <Machine />
        <ThankYou />
      </div>
    </div>
  );
}


export default withRouter(App);
