import {
  MACHINE_FOUND,
  MACHINE_SUCCESSFULLY_REGISTERED,
  SERIAL_NUMBER_CHANGED,
  SERIAL_NUMBER_SUBMITTED,
} from "./actionTypes";

export function machineFound(machine) {
  return { type: MACHINE_FOUND, machine };
}

export function machineSuccessfullyRegistered(songCredits) {
  return { type: MACHINE_SUCCESSFULLY_REGISTERED, songCredits };
}

export function serialNumberChanged(value) {
  return { type: SERIAL_NUMBER_CHANGED, value };
}

export function serialNumberSubmitted(skipMachinePreview) {
  return { type: SERIAL_NUMBER_SUBMITTED, skipMachinePreview };
}
