import React, { useEffect } from "react";
import { connect } from "react-redux";

import Button from "../../component/Button/Button";
import lokaliseStr from "../../service/util/lokaliseStr";
import { classList } from "../../service/util/util";

import { pageActionTriggered } from "../../actions/navigationActions";
import { registrationSkipped, nextPage } from "../../actions/navigationActions";
import {
  serialNumberChanged,
  serialNumberSubmitted,
} from "../../actions/machineActions";

import "./Machine.css";
import { PAGES, MACHINE_STATUS } from "../../constants";

export function Machine({ active, dispatch, langcode, state, serial }) {
  useEffect(() => {
    dispatch(serialNumberChanged(serial));
    // when we get the serial from the search query param, we want to go directly to the next page
    if (serial) {
      dispatch(serialNumberSubmitted(true));
    }
  }, [serial]);

  if (!active) {
    return null;
  }

  function handleSubmit(event) {
    event.preventDefault();
    dispatch(serialNumberSubmitted());
  }

  return (
    <div
      id="page-machine-serial"
      className={classList({
        sending: state.status === MACHINE_STATUS.SENDING,
      })}
    >
      <form className="input-centering" onSubmit={handleSubmit}>
        <input
          onChange={(event) =>
            dispatch(serialNumberChanged(event.target.value))
          }
          placeholder={lokaliseStr(langcode, "Machine.Placeholder")}
          type="search"
          value={state.serialNumber}
        />
      </form>

      {/* ERROR MESSAGES */}

      {state.status === MACHINE_STATUS.SHOW && state.alreadyRegistered && (
        <p className="error-message">
          {lokaliseStr(langcode, "Machine.MachineAlreadyRegisteredWarning")}
        </p>
      )}

      {state.status === MACHINE_STATUS.NOT_FOUND && (
        <p className="error-message">
          {lokaliseStr(langcode, "Machine.MachineNotFoundError")}
        </p>
      )}

      {state.status === MACHINE_STATUS.NETWORK_ERROR && (
        <p className="error-message">
          {lokaliseStr(langcode, "Common.NetworkError")}
        </p>
      )}

      {/* INPUT PROMPT */}

      {state.status !== MACHINE_STATUS.SHOW && (
        <>
          <p>{lokaliseStr(langcode, "Machine.Introduction")}</p>
          <p>{lokaliseStr(langcode, "Machine.ExampleFormat")}</p>

          <Button
            centered
            className="submit-button"
            onClick={() => dispatch(serialNumberSubmitted())}
          >
            {lokaliseStr(langcode, "Machine.Submit")}
          </Button>

          <div className="skip-registration-container">
            <span
              className="skip-registration"
              onClick={() => dispatch(registrationSkipped())}
            >
              {lokaliseStr(langcode, "Machine.SkipRegistration")}
            </span>
          </div>
        </>
      )}

      {/* MACHINE PREVIEW */}

      {state.status === MACHINE_STATUS.SHOW && (
        <MachinePreview
          imageSrc={state.machineImageSrc}
          langcode={langcode}
          onContinue={() => dispatch(nextPage())}
          name={state.machineName}
        />
      )}
    </div>
  );
}

function MachinePreview({ onContinue, imageSrc, langcode, name }) {
  return (
    <>
      <div className="machine-preview">
        <p className="machine-name">{name}</p>

        <div className="machine-image">
          <img alt={name} src={imageSrc} />
        </div>
      </div>

      <Button centered className="continue-button" onClick={onContinue}>
        {lokaliseStr(langcode, "Common.Continue")}
      </Button>
    </>
  );
}

// REDUXIFY IT!

const mapStateToProps = (state) => ({
  active: state.pageId === PAGES.MACHINE,
  langcode: state.langcode,
  state: state.page.Machine,
  serial: state.serial,
});

const mapDispatchToProps = (dispatch) => ({
  dispatch: (child) => dispatch(pageActionTriggered(PAGES.MACHINE, child)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Machine);
