import { call, put, select, take } from "redux-saga/effects";

import scrollToTop from "../service/effect/scrollToTop";
import registerMachine from "../service/network/registerMachine";
import userSelectKaraokeFileFormatOrModel from "../service/network/userSelectKaraokeFileFormatOrModel";

import { PAGE_ACTION_TRIGGERED, CONTINUE_SELECTED } from "../actions/actionTypes";
import { pageActionTriggered } from "../actions/navigationActions";
import { nextPage } from "../actions/navigationActions";
import { appStatusUpdated } from "../actions/contextActions";
import { machineSuccessfullyRegistered } from "../actions/machineActions";
import { PAGES, COMMUNICATIONS_STATUS } from "../constants";


export default function* communicationsSaga() {
  const rootAction = yield take(action => action.type === PAGE_ACTION_TRIGGERED && action.page === PAGES.COMMUNICATIONS);
  const action = rootAction.child;

  if (action.type === CONTINUE_SELECTED) {
    const state = yield select();

    if (state.page.Communications.acceptedSingingMachineCommunication) {
      try {
        yield put(pageActionTriggered(PAGES.COMMUNICATIONS, appStatusUpdated(COMMUNICATIONS_STATUS.SENDING)));

        yield call(userSelectKaraokeFileFormatOrModel, {
          galaxieApiUrl: state.config.galaxieApiUrl,
          sessionToken: state.gxSession,
          code: state.page.Machine.machineMediaFormat,
        });

        const { songCredits } = yield call(registerMachine, {
          email: state.email,
          gxSession: state.gxSession,
          karaokeRegistrationUrl: state.config.karaokeRegistrationUrl,
          language: state.langcode,
          machineModelName: state.page.Machine.machineName,
          machineModelSerialNumberValue: state.page.Machine.serialNumber,
          newsletterOptIn: state.page.Communications.acceptedSingingMachineNewsletter,
          platform: state.platform,
          productName: "KARAOKE",
        });

        yield put(machineSuccessfullyRegistered(songCredits));

        yield put(pageActionTriggered(PAGES.COMMUNICATIONS, nextPage()));
      }
      catch (error) {
        yield put(pageActionTriggered(PAGES.COMMUNICATIONS, appStatusUpdated(COMMUNICATIONS_STATUS.NETWORK_ERROR)));
      }
    }
    else {
      yield put(pageActionTriggered(PAGES.COMMUNICATIONS, appStatusUpdated(COMMUNICATIONS_STATUS.MUST_ACCEPT_COMMUNICATIONS_ERROR)));
    }

    yield call(scrollToTop);  // Scroll up to show error messages
  }
}
