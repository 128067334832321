import { delay } from "../util/util";

/**
 * Sends a machine serial number to the Karaoke Registration.
 *
 * @param {string} serialNumber
 * @param {string} karaokeRegistrationUrl
 * @return {Promise<{ alreadyRegistered: boolean, imageSrc: string, mediaFormat: string, name: string }>}
 */
export default async function submitSerialNumber({ karaokeRegistrationUrl, serialNumber }) {
  try {
    // Make sure a serial number is provided, since the backend expects it
    serialNumber = serialNumber.trim();
    if (serialNumber === "") {
      return null;
    }

    await delay(1000);

    const apiPath = `/karaoke-registration/V2/registration/models/bySerial/${serialNumber}`;
    const result = await fetch(karaokeRegistrationUrl + apiPath, {"mode": "cors"});
    const body = await result.json();

    // status 400: "this serial is not a valid serial"
    // status 404: "model not found"
    if ([400, 404].includes(result.status)) {
      return null;  // Machine not found
    }

    // If the interface of the response changes, throw an error
    const actualFields = Object.keys(body);
    const requiredFields = [
      "already_registered",
      "image_link",
      "media_format_mac",
      "model_name",
    ];

    const missingFields = requiredFields.filter(field => !actualFields.includes(field));
    if (missingFields.length > 0) {
      throw new Error("Some expected fields are missing from response: " + missingFields.join(", "));
    }

    //
    // Note on the media format: All media formats are the same between PC and
    // Mac, except for SMC, where a media_format can be either SMC or CDG. Then,
    // we use the media_format_mac with value "CDG".
    //
    // Note on the name: We use the description of the machine instead of the
    // technical name, because they are generally equivalent, and whenever they
    // differ, the description is a more 'user-friendly' name.
    //
    return {
      alreadyRegistered: !!body.already_registered,
      imageSrc: body.image_link,
      mediaFormat: body.media_format_mac,
      name: body.model_name,
    };
  }
  catch (error) {
    console.error("An error occured while submitting a serial number: ", error);
    throw error;  // Forward network errors
  }
}
