import { APP_STATUS_UPDATED, APP_INITIALIZED } from "./actionTypes";

/**
 * @param {object} config The configuration object
 * @param {object} urlParams URL parameters
 * @param {Date} pageArrivedTime 
 */
export function appInitialized(config, urlParams, pageArrivedTime) {
  return { type: APP_INITIALIZED, config, urlParams, pageArrivedTime };
}

export function appStatusUpdated(status) {
  return { type: APP_STATUS_UPDATED, status };
}