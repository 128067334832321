import { MACHINE_STATUS } from "../../constants";

export default function initialize() {
  return {
    alreadyRegistered: false,
    machineImageSrc: "",
    machineName: "",
    machineMediaFormat: "",
    serialNumber: "",
    status: MACHINE_STATUS.AWAIT_INPUT,  // AwaitInput | MachineNotFoundError | NetworkError | ShowMachine
  };
}
