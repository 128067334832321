import {
  CONTINUE_SELECTED,
  NEXT_PAGE,
  REGISTRATION_SKIPPED,
  PAGE_TRANSITION_TRIGGERED,
  PAGE_ACTION_TRIGGERED,
  HISTORY_UPDATED,
  PAGE_VIEW_CHANGED,
  PAGE_STATE_UPDATED,
  PAGE_TIME_ARRIVED_UPDATED,
} from "./actionTypes";

export function continueClicked() {
  return { type: CONTINUE_SELECTED };
}

export function nextPage() {
  return { type: NEXT_PAGE };
}

export function registrationSkipped() {
  return { type: REGISTRATION_SKIPPED };
}

/**
 * Trigger a transition to a new page, via a saga.
 *
 * @param {string} page  The ID of the target page
 * @param {object} args  The parameters which will be passed to `initialize()`
 */
export function pageTransitionTriggered(page, args) {
  return { type: PAGE_TRANSITION_TRIGGERED, page, args };
}


/**
 * @param {string} page  The ID of the page on which the action occurred
 * @param {object} child  The action which occurred on the page
 */
export function pageActionTriggered(page, child) {
  return { type: PAGE_ACTION_TRIGGERED, page, child };
}


/**
 * Update the History, to make it available in Redux-Saga.
 *
 * @param {string} history  React-router history
 */
export function historyUpdated(history) {
  return { type: HISTORY_UPDATED, history };
}


/**
 * Switch the page that the user is currently seeing.
 *
 * @param {string} page  The ID of the new page
 */
export function pageViewChanged(page) {
  return { type: PAGE_VIEW_CHANGED, page };
}


/**
 * Update the state of a given page, from its ID.
 *
 * @param {string} page  The ID of the page which will be modified
 * @param {object} state  The new state of the page
 */
export function pageStateUpdated(page, state) {
  return { type: PAGE_STATE_UPDATED, page, state };
}


/**
 * Update the timeArrivedToPage.
 *
 * @param {string} timeArrivedToPage  The time at which the user arrives to the page
 */
export function pageTimeArrivedUpdated(timeArrivedToPage) {
  return { type: PAGE_TIME_ARRIVED_UPDATED, timeArrivedToPage };
}