import { COMMUNICATION_PREFERENCE_CHANGED, NEWSLETTER_PREFERENCE_CHANGED, NEXT_PAGE, APP_STATUS_UPDATED } from "../actions/actionTypes";

export default function reducer(state, action) {
  switch (action.type) {

    case NEXT_PAGE:
      return { ...state, status: "Normal" };
    
    case COMMUNICATION_PREFERENCE_CHANGED:
      return { ...state, acceptedSingingMachineCommunication: action.value, status: "Normal" };

    case NEWSLETTER_PREFERENCE_CHANGED:
      return { ...state, acceptedSingingMachineNewsletter: action.value, status: "Normal" };

    case APP_STATUS_UPDATED:
      return { ...state, status: action.status };

    default:
        return state;
  }
}
