import {
  APP_INITIALIZED,
  PAGE_ACTION_TRIGGERED,
  HISTORY_UPDATED,
  PAGE_VIEW_CHANGED,
  PAGE_STATE_UPDATED,
  PAGE_TIME_ARRIVED_UPDATED,
  APP_STATUS_UPDATED,
} from "../actions/actionTypes";

const pageReducers = {
  Communications: require("./communicationsReducer").default,
  Machine: require("./machineReducer").default,
  ThankYou: (state, _action) => state,
};

export default function appReducer(state, action) {
  switch (action.type) {
    // GLOBAL STATE

    case APP_INITIALIZED:
      return {
        ...state,
        config: action.config,
        timeArrivedToPage: action.timeArrivedToPage,
        ...action.urlParams,
      };

      case APP_STATUS_UPDATED:
        return { ...state, status: action.status };

    // PAGE & NAVIGATION

    case PAGE_ACTION_TRIGGERED:
      const pageReducer = pageReducers[action.page];
      const newPageState = pageReducer(state.page[action.page], action.child);
      return { ...state, page: { ...state.page, [action.page]: newPageState } };

    case HISTORY_UPDATED:
      return { ...state, history: action.history };

    case PAGE_VIEW_CHANGED:
      return { ...state, pageId: action.page };

    case PAGE_STATE_UPDATED:
      return { ...state, page: { ...state.page, [action.page]: action.state } };

    case PAGE_TIME_ARRIVED_UPDATED:
      return { ...state, timeArrivedToPage: action.timeArrivedToPage };

    default:
      return state;
  }
}
