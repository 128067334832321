import React from "react";

import Checkbox from "../Checkbox/Checkbox";

import "./LabelledCheckbox.css";


export default function LabelledCheckbox({ checked = false, children, onChange }) {
  return (
    <div className="labelled-checkbox">
      <Checkbox checked={checked} onChange={onChange} />
      <label dangerouslySetInnerHTML={{__html: children}} onClick={() => onChange(!checked)} />
    </div>
  );
}
