/**
 * Makes a call to the Karaoke Registration microservice to update the value of the flag determining if a registration is required.
 *
 * @param {string} email
 * @param {string} karaokeRegistrationUrl
 * @return {boolean}
 */
export default async function updateRegistrationRequestedFlag({ email, karaokeRegistrationUrl }) {
  try {
    const apiPath = `/karaoke-registration/V2/registration/${email}/status`;
    const result = await fetch(karaokeRegistrationUrl + apiPath, {"mode": "cors", "method": "PATCH"});

    if (!result.ok) {
      throw result.statusText;
    }

    return true;
  }
  catch (error) {
    console.error("An error occured while updating registrationRequestedFlag: ", error);
    return false;
  }
}
