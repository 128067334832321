/**
 * Makes a call to the Karaoke Registration to register a machine.
 *
 * @param {string} email
 * @param {string} gxSession
 * @param {string} karaokeRegistrationUrl
 * @param {string} machineModelName
 * @param {string} machineModelSerialNumberValue
 * @param {string} newsletterOptIn
 * @param {string} platform
 * @param {string} productName
 * @return {Object}
 */
export default async function registerMachine({
  email,
  gxSession,
  karaokeRegistrationUrl,
  language,
  machineModelName,
  machineModelSerialNumberValue,
  newsletterOptIn,
  platform,
  productName,
}) {
  try {
    const res = await fetch("/geolocation");
    const { countryCode } = await res.json();

    const pathUrl = "/karaoke-registration/V2/registration";
    const response = await fetch(karaokeRegistrationUrl + pathUrl, {
      mode: "cors",
      method: "POST",
      headers: {
        "Accept": "application/json",
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        email,
        gx_session: gxSession,
        model_name: machineModelName,
        platform,
        product_name: productName,
        serial_number: machineModelSerialNumberValue,
        sm_accept_new_letters: newsletterOptIn,
        ...(language && language.length && { language }),
        ...(countryCode && countryCode.length && { countryCode })
      })
    });

    if (!response.ok) {
      throw response.statusText;
    }

    const result = await response.json();

    let songCredits = 0;

    (result.redeemed_promotions || []).forEach((promotion) => {
      switch (promotion.promotion_unit) {
        case "KARAOKE_SONGS":
          songCredits += promotion.added_promotion_amount;
          break;

        default:
          console.log(
            `Unhandled promotion unit ${promotion.promotion_unit} ` +
            `with value: ${promotion.added_promotion_amount}`);
      }
    });

    return { songCredits };
  }
  catch (error) {
    console.error("An error occured while registering machine: ", error);
    throw error;  // Forward the error
  }
}
