import React from "react";

import "./Button.css";
import { classList } from "../../service/util/util";


export default function Button({ centered = false, children, className, disabled = false, onClick }) {
  const params = {
    className: classList({
      "button": true,
      "disabled": disabled,
      [className]: true,
    }),
    onClick,
  };

  if (typeof children === "string") {
    params.dangerouslySetInnerHTML = { __html: children };
    children = null;
  }

  const button = <span  {...params}>{children}</span>;

  return centered ?
    <div className="button-centering">{button}</div> :
    button;
}
