import React from "react";
import { connect } from "react-redux";

import { pageActionTriggered } from "../../actions/navigationActions";
import { nextPage } from "../../actions/navigationActions";
import Button from "../../component/Button/Button";
import lokaliseStr from "../../service/util/lokaliseStr";

import "./ThankYou.css";
import { PAGES } from "../../constants";


export function ThankYou({ active, dispatch, langcode, songCredits, webPlatform }) {
  if (!active) {
    return null;
  }

  return (
    <div id="page-thank-you">
      <p>{lokaliseStr(langcode, "ThankYou.Message")}</p>

      {(webPlatform && songCredits > 0) && (
        <div className="song-credits">
          <p><strong>{lokaliseStr(langcode, "ThankYou.SongCreditsMessage").replace("{}", songCredits)}</strong></p>
          <p>{lokaliseStr(langcode, "ThankYou.SongCreditsDetails")}</p>
        </div>
      )}

      <p>{lokaliseStr(langcode, "ThankYou.SpecialOffers")}</p>
      <Button centered className="continue-button" onClick={() => dispatch(nextPage())}>
        {lokaliseStr(langcode, "Common.Continue")}
      </Button>
    </div>
  );
}



// REDUXIFY IT!


const mapStateToProps = (state) => ({
  active: state.pageId === PAGES.THANK_YOU,
  langcode: state.langcode,
  songCredits: state.songCredits,
  webPlatform: state.platform === "WEB",
});

const mapDispatchToProps = (dispatch) => ({
  dispatch: (child) => dispatch(pageActionTriggered(PAGES.THANK_YOU, child)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ThankYou);
