import React from "react";

import { classList } from "../../service/util/util";

import "./Checkbox.css";


/**
 * A drop-in replacement for `<input type="checkbox" />`, but with a custom
 * appearance that fits the Karaoke Registration Flow mockups, and a better
 * `onChange` signature (which takes a `boolean`, not an `Event`).
 *
 * @param {object} param0
 * @param {boolean} param0.checked
 * @param {(newChecked: boolean) => void} param0.onChange
 * @return {JSX.Element}
 */
export default function Checkbox({ checked = false, onChange }) {
  const toggle = () => onChange(!checked);

  return (
    <div
      className={classList({ "checkbox": true, "checked": checked })}
      onClick={toggle}
      onKeyUp={onSpace(toggle)}
      tabIndex={0} />
  );
}


/**
 * Returns an event listener which is only fired when the SPACE key is pressed.
 *
 * @param {function} callback
 * @return {function}
 */
function onSpace(callback) {
  return (event) => {
    if (event.which === 32) {  // SPACE
      event.preventDefault();
      callback();
    }
  };
}
