import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { applyMiddleware, createStore } from "redux";
import createSagaMiddleware from "redux-saga";

import App from "./component/app/App";
import appInitialize from "./component/app/appInitialize";
import appReducer from "./reducers/appReducer";
import {appSaga} from "./sagas/appSaga";

import "isomorphic-unfetch";

const sagaMiddleware = createSagaMiddleware();
const store = createStore(appReducer, appInitialize(), applyMiddleware(sagaMiddleware));
sagaMiddleware.run(appSaga);

const rootElement = (
  <Provider store={store}>
    <BrowserRouter basename="/">
      <App dispatch={store.dispatch} />
    </BrowserRouter>
  </Provider>
);

ReactDOM.render(rootElement, document.getElementById("root"));
