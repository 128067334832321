import moment from "moment";

/**
 * Makes a call to Galaxie API to log a navigation event.
 */
export default async function logNavigationEvent({ galaxieApiUrl, source, destination, sessionToken, thinkTimeSec }) {
  const date = new Date();

  const data = {
    session_token: sessionToken || "",
    log_item: [{
      source,
      event: "Navigate",
      destination,
      event_selected_option: "",
      think_time_sec: thinkTimeSec,
      event_time: moment(date).format("YYYY-MM-DDTHH:mm:ss")
    }]
  };

  try {
    // fetch to galaxie api
    const response = await fetch(`${galaxieApiUrl}/api/LogNavigationEvent`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify(data)
    });

    if (!response.ok) {
      throw new Error();
    }

    return await response.json();
  }
  catch (error) {
    console.error("An error occured while sending BI log: ", error);
    return null;
  }
}
