/**
 * Fetch the config.
 *
 * @return {object}
 */
export default async function fetchConfig() {
  try {
    const result = await fetch("/config");

    if (!result.ok) {
      throw result.statusText;
    }

    return await result.json();
  }
  catch (error) {
    console.error("An error occured while fetching partnerships: ", error);
    return null;
  }
}
