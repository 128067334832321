const translations = {
  en: require("../../lokalise/en.json"),
  fr: require("../../lokalise/fr.json"),
};


/**
 * Gets a given string from the Lokalise translations, in a given language.
 *
 * If the key is invalid, throw an error.
 * If it is not translated in that language, default to English.
 *
 * @param {string} langcode
 * @param {string} key
 * @return {string}
 */
export default function lokaliseStr(langcode = "en", key) {
  const str = (translations[langcode] && translations[langcode][key]) || translations.en[key];

  if (str === undefined) {
    throw new Error("Cannot get translation for: " + key);
  }

  return str;
}
