export default function getParameters(search = "") {
  search = search || window.location.search;
  search = search.substr(1);

  if (!search) {
    return {};
  }

  const params = {};
  const paramPairs = search.split("&").map(param => param.split("="));
  for (const [key, value] of paramPairs) {
    params[decodeURIComponent(key)] = decodeURIComponent(value);
  }

  return params;
}
