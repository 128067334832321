import moment from "moment";
import { matchPath } from "react-router";
import { eventChannel } from "redux-saga";
import { call, put, select, spawn, take } from "redux-saga/effects";

import logNavigationEvent from "../service/network/logNavigationEvent";
import communicationsSaga from "../sagas/communicationsSaga"
import machineSerialSaga from "../sagas/machineSaga";
import fetchConfig from "../service/network/fetchConfig";
import getParameters from "../service/util/getParameters";

import appRoutes from "../component/app/appRoutes";

import {
  PAGE_TRANSITION_TRIGGERED,
  PAGE_ACTION_TRIGGERED,
  NEXT_PAGE,
  HISTORY_UPDATED,
} from "../actions/actionTypes";
import {
  pageTimeArrivedUpdated,
  pageTransitionTriggered,
  pageStateUpdated,
  pageViewChanged,
} from "../actions/navigationActions";
import { appInitialized } from "../actions/contextActions";
import { PAGES } from "../constants";


/**
 * One saga to rule them all.  (ง'̀-'́)ง
 * Basically, all it does is start the other sagas of the application.
 */
export function* appSaga() {
  yield call(initSaga);
  yield spawn(navigationSaga);
  yield spawn(openPageSaga);

  // Page-specific sagas
  yield spawn(machineSerialSagaWrapper);
  yield spawn(communicationsSagaWrapper);
}


function* machineSerialSagaWrapper() {
  for (;;) {
    yield machineSerialSaga();
  }
}


function* communicationsSagaWrapper() {
  for (;;) {
    yield communicationsSaga();
  }
}

/**
 * Initializes the application, and boots the first page.
 */
export function* initSaga() {
  // Setup onRouteChangeSaga
  yield take(HISTORY_UPDATED);
  const state = yield select();
  const historyChannel = yield call(createHistoryChannel, state.history);
  yield spawn(onRouteChangeSaga, historyChannel);

  // Get karaoke registration microservice url
  const config = yield fetchConfig();

  // Remove URL parameters from the URL, and store them in the state
  const params = getParameters();
  const urlParams = {
    email: params.email || "",
    gxSession: params.gx_session || "",
    langcode: params.langcode || "en",
    platform: params.platform || "WEB",
    skipPath: params.skip_path || "/skip",
    successPath: params.success_path || "/success",
    serial:params.serial || "",
  };

  const pageArrivedTime = new Date();

  yield put(appInitialized(config, urlParams, pageArrivedTime));

  // Navigate to the initial page
  yield call(state.history.push, appRoutes.Machine);
}


function createHistoryChannel(history) {
  return eventChannel(emitter => {
    const unlisten = history.listen(() => onRouteChange(emitter, history));

    return unlisten;
  });
}


/**
 * Listens to route changes. Takes a Redux Saga Channel.
 *
 * @param {Object} historyChannel
 */
export function* onRouteChangeSaga(historyChannel) {
  for (;;) {
    const state = yield select();

    try {
      const pageId = yield take(historyChannel);
      yield put(pageTransitionTriggered(pageId));
    }
    catch(err) {
      yield call(state.history.push, appRoutes.Machine);
    }
  }
}


function onRouteChange(emitter, history) {
  const pathname = history.location.pathname;

  // Find out what route corresponds to the URL
  for (const pageId of Object.keys(appRoutes)) {
    const path = appRoutes[pageId];
    const match = matchPath(pathname, { path, exact: true, strict: false });

    if (match) {
      emitter(pageId);

      return;
    }
  }

  emitter(new Error("---unreachable---"));
}


/**
 * Listen for OpenPage actions and transition to the given page.
 *
 * If the page has not been previously initialized, do so beforehand, and only
 * move to the new page once it is ready.
 */
export function* openPageSaga() {
  // The function which should be called to initialize each page
  const pageInitializers = {
    Communications: require("../page/Communications/initialize").default,
    Machine: require("../page/Machine/initialize").default,
    ThankYou: () => ({}),
  };

  for (;;) {
    const { page, args } = yield take(PAGE_TRANSITION_TRIGGERED);
    const state = yield select();

    if (state.pageId) {
      yield call(logNavigationEventSaga, { destination: page });
    }

    // Initialize the page if it does not yet exist
    if (!state.page[page]) {
      const initializer = pageInitializers[page];
      const initState = yield call(initializer, args);
      yield put(pageStateUpdated(page, initState));
    }

    // Once the page is ready, transition to it
    yield put(pageViewChanged(page));
    yield put(pageTimeArrivedUpdated(new Date()));
  }
}


/**
 * Listen to some actions from the pages, such as `Continue`, which should open
 * another page, and perform the necessary actions to transition to this page.
 *
 * This typically involve gathering some data, then dispatching an `OpenPage`
 * action which will be caught by `openPageSaga()`.
 */
export function* navigationSaga() {
  // We start on the loading page, until the JS is loaded

  for (;;) {
    yield take(action => action.type === PAGE_ACTION_TRIGGERED && action.child.type === NEXT_PAGE);
    const state = yield select();

    switch (state.pageId) {
      case PAGES.MACHINE:
        yield call(state.history.push, appRoutes.Communications);
        break;
      case PAGES.COMMUNICATIONS:
        yield call(state.history.push, appRoutes.ThankYou);
        break;
      case PAGES.THANK_YOU:
        yield call(redirectToSuccess);
        break;
      default:
        throw new Error("---unreachable---");
    }
  }
}


/**
 * Once the registration is complete, notify the parent application.
 */
export function* redirectToSuccess() {
  const state = yield select();

  window.location = state.successPath;
}


/**
 * If the registration is cancelled, notify the parent application.
 */
export function* redirectToSkip() {
  const state = yield select();

  window.location = state.skipPath;
}


function* logNavigationEventSaga({ destination }) {
  const state = yield select();

  const now = new Date();
  const then = state.timeArrivedToPage;
  const thinkTimeMS = moment(now, "YYYY-MM-DDTHH:mm:ss").diff(moment(then, "YYYY-MM-DDTHH:mm:ss"));
  const thinkTimeSec = moment.duration(thinkTimeMS, 'milliseconds').seconds();

  const prefixes = {
    Machine: "Reg1",
    Communications: "Reg2",
    ThankYou: "Reg3",
  };

  yield call(logNavigationEvent, {
    galaxieApiUrl: state.config.galaxieApiUrl,
    source: `${prefixes[state.pageId]}_${state.pageId}`,
    destination: `${prefixes[destination]}_${destination}`,
    sessionToken: state.gxSession,
    thinkTimeSec
  });
}
