export const PAGES = {
  MACHINE: "Machine",
  COMMUNICATIONS: "Communications",
  THANK_YOU: "ThankYou",
};

export const COMMUNICATIONS_STATUS = {
  SENDING: "Sending",
  MUST_ACCEPT_COMMUNICATIONS_ERROR: "MustAcceptCommunicationsError",
  NETWORK_ERROR: "NetworkError",
}

export const MACHINE_STATUS = {
  SENDING: "Sending",
  AWAIT_INPUT: "AwaitInput",
  SHOW: "Show",
  NOT_FOUND: "NotFound",
  NETWORK_ERROR: "NetworkError",
}
